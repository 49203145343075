/*!===========================
  Owl Carousel
  ==============================*/

function ajax_carousel(url) {

  $.ajax({
    url: url,
    dataType: "json",
    success: function (rs) {
      rs.animIn == 'default' ? rs.animIn = false : rs.animIn = rs.animIn
      rs.animOut == 'default' ? rs.animOut = false : rs.animOut = rs.animOut

      carousel(rs)
    }
  })

}

function carousel(settings) {
  $('.owl-carousel').owlCarousel({
    loop: true,
    items: 1,
    nav: false,
    dots: false,
    autoplay: settings.autoplay,
    autoplaytimeout: settings.timeout,
    animateIn: settings.animIn,
    animateOut: settings.animOut
  })
}

ajax_carousel('http://localhost/kejaksaan/owl.php')
