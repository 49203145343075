/*!===========================
  Charts config
  ==============================*/

Chart.defaults.global.defaultFontFamily = "Poppins, sans-serif"
Chart.defaults.global.defaultFontColor = "#333"

function ajax_chart(url, ctx) {
  var colors = [
    '#fb9678',
    '#343a40',
    '#01c0c8',
    '#e46a76',
    '#fec107',
    '#00c292',
    '#03a9f3',
    '#6610f2'
  ]

  ctx = document.getElementById(ctx)

  $.ajax({
    url: url,
    dataType: "json",
    success: function (rs) {
      var legends
      rs.datasets[0].label == undefined ? legends = false : legends = true
      
      switch (rs.type) {
        case 'bar':
          var data = [],
            j = 0
          $.each(rs.datasets, function (i, val) {
            j > 7 ? j = 0 : j = j
            data.push({
              label: val.label,
              backgroundColor: colors[j],
              borderWidth: 0,
              data: val.data
            })
            j = j + 1
          })

          data = JSON.stringify(data)
          chart_bar(ctx, JSON.parse(data), rs.labels, legends)
          break
        case 'line':
          var data = [],
            j = 0
          $.each(rs.datasets, function (i, val) {
            j > 7 ? j = 0 : j = j
            data.push({
              label: val.label,
              backgroundColor: colors[j],
              borderColor: colors[j],
              data: val.data,
              fill: false
            })
            j = j + 1
          })

          data = JSON.stringify(data)
          chart_line(ctx, JSON.parse(data), rs.labels, legends)
          break
      }
    }
  })
}

/*!===========================
  Charts custom
  ==============================*/

ajax_chart('http://localhost/kejaksaan/data.php', 'korupsi', 'bar')
ajax_chart('http://localhost/kejaksaan/data2.php', 'narkoba', 'line')
ajax_chart('http://localhost/kejaksaan/data3.php', 'pilgub', 'bar')
